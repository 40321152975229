import { Module, Node } from "@msdyn365-commerce-modules/utilities";
import React, { useState } from "react";

import {
  IBuyboxAddToCartViewProps,
  IBuyboxKeyInPriceViewProps,
  // IBuyboxProductConfigureDropdownViewProps,
  // IBuyboxProductConfigureViewProps,
  IBuyboxProductQuantityViewProps,
} from "@msdyn365-commerce-modules/buybox/src/modules/quickview/../../common";
import { IQuickviewViewProps } from "@msdyn365-commerce-modules/buybox/src/modules/quickview/./quickview";
import {
  CartLine,
  SimpleProduct,
  ProductPrice,
} from "@msdyn365-commerce/retail-proxy";

import {
  addCartLinesAsync,
  validateForCheckoutAsync,
} from "@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g";
import { getCartState } from "@msdyn365-commerce/global-state";
import {
  getVariantsByDimensionValuesAsync,
  getAttributeValuesAsync,
  getByIdAsync,
  getActivePricesAsync,
} from "@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";

// import _ from "lodash-es";
import {
  IImageData,
  Image,
  IImageSettings,
  IGridSettings,
} from "@msdyn365-commerce/core";
import { IProductInfo } from "@msdyn365-commerce/telemetry-internal";
import { getEstimatedAvailabilityAsync } from "@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g";
import { getProductsReferenceNumberAsync } from "../actions/get-reference-number";
import { toast } from "react-toastify";
import { ATPStateObjectType, ATPAPIRequestObject } from "./buybox.view";
import { getOnlyDate } from "../../../utilities/renderDates";

import {
  BritaxGetAtpDateInput,
  getAtpDateAction,
} from "../../../actions/britax-get-atp-date.action";
// import CurrencyFormatter from "currency-formatter";

const _renderAddToCart = (
  addToCart: IBuyboxAddToCartViewProps,
  context: any,
  AddProductsToCart: any,
  props: any,
  isRetired: any,
  setIsRetired: any,
  error?: any
): JSX.Element => {
  const { ContainerProps, errorBlock } = addToCart;

  const classExists: any =
    document.getElementsByClassName("msc-alert msc-alert-danger-custom")
      .length > 0;

  if (classExists || error) {
    //error exists
    return (
      <Node {...ContainerProps}>
        {/* {button} */}
        <button
          className="msc-add-to-cart custom-btn-add-to-cart"
          disabled={true}
        >
          {props.resources.addToCart}
        </button>
      </Node>
    );
  } else {
    getAttributeValuesAsync(
      { callerContext: context.actionContext, queryResultSettings: {} },
      props.selectedProductId,
      context.request.apiSettings.channelId,
      Number(context.request.user.catalogId) // Saqlain Customization: Catalog id '0' was replaced by 'Number(context.request.User.CatalogId)'
    ).then((res) => {
      res.map((d: any) => {
        if (d.Name == "IsRetired") {
          if (d.BooleanValue == true) {
            isRetired = true;
            setIsRetired(true);
          }
        }
      });
    });
    if (isRetired) {
      return <Node {...ContainerProps}></Node>;
    } else {
      return (
        <>
          {errorBlock}
          {/* {button} */}
          <button
            onClick={AddProductsToCart}
            className="msc-add-to-cart custom-btn-add-to-cart"
          >
            {props.resources.addToCart}
          </button>
        </>
      );
    }
  }
};

// const _renderAddToWishlist = (addToWishlist: IBuyboxAddToWishlistViewProps): JSX.Element => {
//     const { ContainerProps, errorBlock, button } = addToWishlist;

//     return (
//         <Node {...ContainerProps}>
//             {errorBlock}
//             {button}
//         </Node>
//     );
// };

// const _renderConfigureDropdown = (
//   dropdown: IBuyboxProductConfigureDropdownViewProps
// ): JSX.Element => {
//   const {
//     ContainerProps,
//     LabelContainerProps,
//     heading,
//     errors,
//     select,
//   } = dropdown;

//   return (
//     <Node {...ContainerProps}>
//       <Node {...LabelContainerProps}>
//         {heading}
//         {errors}
//       </Node>
//       {select}
//     </Node>
//   );
// };

const _renderQuickViewPopup = (props: any): JSX.Element => {
  const {
    ModalContainer,
    ModalHeaderContainer,
    ModalFooterContainer,
    ModalHeaderContent,
    ModalBodyContainer,
    // addToCart,
    cartContainerProps,
  } = props;

  return (
    <Module {...ModalContainer}>
      <Node {...ModalHeaderContainer}>{ModalHeaderContent}</Node>
      <Node {...ModalBodyContainer}>{_renderBodyContent(props)}</Node>
      <Node {...ModalFooterContainer}>
        <Node {...cartContainerProps}>
          {/* {addToCart && _renderAddToCart(addToCart)} */}
        </Node>
      </Node>
    </Module>
  );
};

let storeIndexes: number[] = [];
const _renderQuantity = (
  quantity: IBuyboxProductQuantityViewProps,
  product: any,
  index: any,
  onProductQuantityChange: any,
  context: any,
  products: any,
  addQty: any,
  subtractQty: any,
  availStatus: any,
  recordIds: any,
  props: any,
  quantityAvail: any,
  isRetired: any,
  multipleError: any,
  productDetail: any,
  openNotificationLimit: any,
  referenceNumber: any,
  renderTooltip: any,
  fullForms: any,
  atpDate: ATPStateObjectType,
  detectQtyChange: any
): JSX.Element => {
  const { ContainerProps, LabelContainerProps, errors } = quantity;
  let atpMessageField: string = "";
  if (
    atpDate &&
    atpDate.atpDate !== "_" &&
    storeIndexes.includes(index) &&
    detectQtyChange.isChanged
  ) {
    atpMessageField = "NA";
  } else if (atpDate) {
    if (atpDate.atpDate === "_" || atpDate.currentQuantity === 0) {
      atpMessageField = "";
    } else {
      atpMessageField = atpDate.atpDate;
    }
  }
  const defaultImageSettings: IImageSettings = {
    viewports: {
      xs: { q: `w=80&h=80&m=6`, w: 0, h: 0 },
      lg: { q: `w=80&h=80&m=6`, w: 0, h: 0 },
      xl: { q: `w=80&h=80&m=6`, w: 0, h: 0 },
    },
    lazyload: true,
    cropFocalRegion: true,
  };
  const img: IImageData = {
    src: context.request.apiSettings.baseImageUrl + product.imgUrl,
    // src:'',
    altText: "pic",
  };
  const imageProps = {
    gridSettings: context.request.gridSettings,
    imageSettings: defaultImageSettings,
  };
  // const currencyCode = props?.data?.customerInformation?.result?.CurrencyCode;

  if (product.Behavior.MultipleOfQuantity > 1) {
    if (product.Count % product.Behavior.MultipleOfQuantity == 0) {
      //set state as false;
      multipleError[index] = "false";
    } else {
      //set state as true;
      multipleError[index] = "true";
    }
  }

  return (
    <Node {...ContainerProps}>
      <Node {...LabelContainerProps}>
        {/* {heading} */}
        {errors}
      </Node>
      <div className="product-variant" key={index}>
        <div className="variant-blk">
          <div className="img-box">
            <Image {...img} {...imageProps} loadFailureBehavior="empty" />
            <h5 className="name">{product.Value}</h5>
            <span>
              {referenceNumber[index]?.ReferenceNumber
                ? referenceNumber[index]?.ReferenceNumber
                : " "}
            </span>

            {isRetired != true ? (
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={(e) =>
                  renderTooltip(
                    fullForms[availStatus[recordIds.indexOf(product.RecordId)]],
                    e
                  )
                }
              >
                <span
                  className={`status ${
                    availStatus[recordIds.indexOf(product.RecordId)]
                  }`}
                >
                  {fullForms[availStatus[recordIds.indexOf(product.RecordId)]]}
                </span>
              </OverlayTrigger>
            ) : (
              <></>
            )}
          </div>
        </div>
        {isRetired != true ? (
          <div className="variant-blk">
            {product.DiscountAmount > 0 ? (
              <h5>
                <s>
                  {props.context.cultureFormatter.formatCurrency(
                    Number(product.CustomerContextualPrice)
                  )}
                </s>
              </h5>
            ) : (
              <h5>
                {props.context.cultureFormatter.formatCurrency(
                  Number(product.CustomerContextualPrice)
                )}
              </h5>
            )}

            <h5>
              {product.DiscountAmount > 0
                ? props.context.cultureFormatter.formatCurrency(
                    Number(
                      product?.CustomerContextualPrice - +product.DiscountAmount
                    )
                  )
                : null}
            </h5>
          </div>
        ) : (
          <></>
        )}

        {isRetired != true ? (
          <div className="variant-blk">
            <span className="quantity-box">
              {props.context.app.config.enableStockCheck == true &&
              availStatus[recordIds.indexOf(product.RecordId)] == "OOS" ? (
                <button disabled={true}>
                  <span>-</span>
                </button>
              ) : (
                <button
                  className={product.Count > 0 ? " " : "disabled"}
                  onClick={() => subtractQty(index)}
                >
                  <span>-</span>
                </button>
              )}
              {/* <button onClick={() => subtractQty(index)}>-</button> */}

              {/* new working */}

              {props.context.app.config.enableStockCheck == true &&
              availStatus[index] == "OOS" ? (
                <input
                  type="number"
                  disabled={true}
                  max={
                    quantityAvail[index] <
                    Number(props.context.app.config.maxQuantityForCartLineItem)
                      ? Number(
                          props.context.app.config.maxQuantityForCartLineItem
                        )
                      : quantityAvail[index]
                  }
                  onKeyDown={(event: any) => {
                    if (event.key === ".") {
                      event.preventDefault();
                    }
                  }}
                  onInput={(event: any) =>
                    (event.target.value = event.target.value.replace(
                      /[^0-9]*/g,
                      ""
                    ))
                  }
                  id={product.imgUrl}
                />
              ) : props.context.app.config.enableStockCheck ? (
                <input
                  type="number"
                  max={
                    quantityAvail[index] <
                    Number(props.context.app.config.maxQuantityForCartLineItem)
                      ? Number(
                          props.context.app.config.maxQuantityForCartLineItem
                        )
                      : quantityAvail[index]
                  }
                  id={product.imgUrl}
                  value={product.Count}
                  onChange={onProductQuantityChange(
                    index,
                    quantityAvail[index] <
                      Number(
                        props.context.app.config.maxQuantityForCartLineItem
                      )
                      ? Number(
                          props.context.app.config.maxQuantityForCartLineItem
                        )
                      : quantityAvail[index]
                  )}
                  onKeyDown={(event: any) => {
                    if (event.key === ".") {
                      event.preventDefault();
                    }
                  }}
                  onInput={(event: any) =>
                    (event.target.value = event.target.value.replace(
                      /[^0-9]*/g,
                      ""
                    ))
                  }
                />
              ) : product?.Behavior?.MaximumQuantity == 0 &&
                product?.Behavior?.MinimumQuantity == 0 ? (
                <input
                  type="number"
                  id={product.imgUrl}
                  value={product.Count}
                  onKeyDown={(event: any) => {
                    if (event.key === ".") {
                      event.preventDefault();
                    }
                  }}
                  onInput={(event: any) =>
                    (event.target.value = event.target.value.replace(
                      /[^0-9]*/g,
                      ""
                    ))
                  }
                  onChange={onProductQuantityChange(
                    index,
                    quantityAvail[index] <
                      Number(
                        props.context.app.config.maxQuantityForCartLineItem
                      )
                      ? Number(
                          props.context.app.config.maxQuantityForCartLineItem
                        )
                      : quantityAvail[index]
                  )}
                />
              ) : (
                <input
                  type="number"
                  id={product.imgUrl}
                  value={product.Count}
                  onKeyDown={(event: any) => {
                    if (event.key === ".") {
                      event.preventDefault();
                    }
                  }}
                  onInput={(event: any) =>
                    (event.target.value = event.target.value.replace(
                      /[^0-9]*/g,
                      ""
                    ))
                  }
                  onChange={onProductQuantityChange(
                    index,
                    product?.Behavior.MaximumQuantity
                  )}
                />
              )}

              {/* closed new working */}

              {props.context.app.config.enableStockCheck == true &&
              availStatus[index] == "OOS" ? (
                <button disabled={true}>
                  <span>+</span>
                </button>
              ) : props.context.app.config.enableStockCheck ? (
                quantityAvail[index] >
                Number(props.context.app.config.maxQuantityForCartLineItem) ? (
                  <button
                    onClick={() =>
                      product.Count <
                      Number(
                        props.context.app.config.maxQuantityForCartLineItem
                      )
                        ? addQty(index)
                        : openNotificationLimit()
                    }
                  >
                    <span>+</span>
                  </button>
                ) : (
                  <button
                    onClick={() =>
                      product.Count < quantityAvail[index]
                        ? addQty(index)
                        : openNotificationLimit()
                    }
                  >
                    <span>+</span>
                  </button>
                )
              ) : product?.Behavior?.MaximumQuantity == 0 &&
                product?.Behavior?.MinimumQuantity == 0 ? (
                <button onClick={() => addQty(index)}>
                  <span>+</span>
                </button>
              ) : (
                <button
                  onClick={() =>
                    product.Behavior?.MaximumQuantity == 0
                      ? addQty(index)
                      : product.Count < product.Behavior?.MaximumQuantity
                      ? addQty(index)
                      : openNotificationLimit()
                  }
                >
                  <span>+</span>
                </button>
              )}
            </span>
          </div>
        ) : (
          <></>
        )}

        {isRetired != true ? (
          <div className="variant-blk">
            <h5>
              {props.context.cultureFormatter.formatCurrency(
                Number(
                  product.Count *
                    (product.CustomerContextualPrice - product.DiscountAmount)
                )
              )}
            </h5>
          </div>
        ) : (
          <></>
        )}
        {isRetired != true ? (
          <div className="variant-blk">
            <h5>{atpMessageField}</h5>
          </div>
        ) : (
          <></>
        )}
      </div>
      {/* <div className="row">
        <div className="col">
          <img
            src={context.request.apiSettings.baseImageUrl + product.imgUrl}
          />
        </div>
        <div className="col">
          <h5 className="name">{product.Value}this</h5>
          <h5>€{product.Price}</h5>
          <span className="quantity-box">
            <button onClick={() => subtractQty(index)}>-</button>
            <input
              type="number"
              min="0"
              id={product.imgUrl}
              value={product.Count}
              onChange={onProductQuantityChange(index)}
            />
            <button onClick={() => addQty(index)}>+</button>
          </span>
        </div>
      </div> */}
      <br />
      {multipleError[index] == "true" ? (
        <span className="msc-alert msc-alert-danger-custom">
          {props.resources.multiOf} {product.Behavior.MultipleOfQuantity}{" "}
          {props.resources.isAllowed}.
        </span>
      ) : (
        <></>
      )}
    </Node>
  );
};

const _renderKeyInPrice = (
  keyInPrice: IBuyboxKeyInPriceViewProps
): JSX.Element => {
  const { ContainerProps, LabelContainerProps, heading, input } = keyInPrice;
  return (
    <Node {...ContainerProps}>
      <Node {...LabelContainerProps}>{heading}</Node>
      {input}
    </Node>
  );
};

// const _renderConfigure = (
//   configure: IBuyboxProductConfigureViewProps
// ): JSX.Element => {
//   const { ContainerProps, dropdowns } = configure;

//   return (
//     <Node {...ContainerProps}>{dropdowns.map(_renderConfigureDropdown)}</Node>
//   );
// };

const _renderBodyContent = (props: any): JSX.Element => {
  const {
    title,
    rating,
    keyInPrice,
    quantity,
    // configure,
    inventoryLabel,
    loading,
    ProductInfoContainerProps,
    MediaGalleryContainerProps,
    CarouselProps,
    description,
    context,
    addToCart,
    selectedProductId,
    price,
    // state,
  } = props;
  var fullForms: any = [];
  fullForms["OOS"] = props.resources.outOfStock;
  fullForms["LOW"] = props.resources.lowInStock;
  fullForms["AVAIL"] = props.resources.available;

  const openNotification = () => {
    // notification["success"]({
    //   message: props.resources.itemAdded,
    //   description: props.resources.productAdded,
    //   onClick: () => {

    //   },
    // });
    toast.dark(
      <>
        <h5>{props.resources.itemAdded}</h5>
        <p>{props.resources.productAdded}</p>
      </>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );
  };

  const openNotificationMin = (e: any) => {
    toast.error(e.message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    // notification["error"]({
    //   message: e.message,
    //   onClick: () => {

    //   },
    // });
  };

  const openNotificationLimit = () => {
    toast.error(props.resources.limitedReached, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    // notification["error"]({
    //   message: props.resources.limitedReached,
    //   onClick: () => {

    //   },
    // });
  };

  const [products, setProducts] = useState<any>([]);
  const [items, setitems] = useState<any>([]);
  const [fewItems, setFewItems] = useState<any>([]);
  const [error, setError] = useState<Boolean>(false);
  const [show] = useState(false);
  const [cartRes, setCartRes] = useState<any>({});
  const [productUrl, setProductUrl] = useState<any>("");
  const [maxQuantity, setMaxQuantity] = useState<any>(0);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [spareQuantity, setSpareQuantity] = useState<any>(0);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [availStatus, setAvailStatus] = useState<any>([]);
  const [recordsIds, setRecordIds] = useState<any>([]);
  const [quantityAvail, setQuantityAvail] = useState<any[]>([]);
  const [MaxQuantityError, setMaxQuantityError] = useState<Boolean>(false);
  let [isRetired, setIsRetired] = useState<boolean>(false);
  let [totalPrice, setTotalPrice] = useState<number>(0);
  let [productCounts, setProductCounts] = React.useState();
  const [productDetail, setProductDetail] = useState<any>({});
  const [simpleProductAtpDate, setSimpleProductAtpDate] = useState<any[]>([]);
  const [detectQtyChange, setDetectQtyChange] = useState<any>({});
  const [atpLoader, setAtpLoader] = useState<boolean>(false);

  //State for ATP - Saqlain Customization - ADO 959.
  // const [atpDate, setatpDate] = useState<ATPStateObjectType[]>([]);
  const [atpDate, setatpDate] = useState<any[]>([]);

  // this useEffect will initialize the state for ATP SAQO.
  React.useEffect(() => {
    const tempItems = [...items];
    //SAQOO
    if (referenceNumber.length > 0) {
      if (atpDate && atpDate.length !== 0) setatpDate([]);
      items &&
        items.map((item: any, index: number) => {
          let Counts: number = tempItems[index].Count;

          let RecId: number = item.RecordId;
          let productReferenceNumber: number = 0;
          if (referenceNumber.length > 0) {
            productReferenceNumber =
              referenceNumber[index].Id === RecId
                ? referenceNumber[index].ReferenceNumber
                : null;
          }

          let constructObjectforEachAtp: ATPStateObjectType = {
            productRecordID: RecId,
            productReferenceNumber: productReferenceNumber,
            currentQuantity: Counts,
            lastQuantityUsedToFetchDate:
              atpDate[index]?.lastQuantityUsedToFetchDate,
            atpDate: atpDate[index]?.atpDate ? atpDate[index].atpDate : "_",
            // underscore is a dummy value that is being set so we know that it is the first render and we don't have any value yet.
          };
          setatpDate((prevState) => [...prevState, constructObjectforEachAtp]);
        });
    }
  }, [items, productCounts]);

  const changeSpareQuantity = (qty: number) => {
    setDetectQtyChange({ index: 0, isChanged: true });
    setSpareQuantity(qty);
  };

  React.useEffect(() => {
    var tempCounts: any = 0;
    items.map((item: any) => {
      tempCounts += item.Count;
    });
    setProductCounts(tempCounts);
    tempCounts = 0;
  }, [items]);

  React.useEffect(() => {
    let price = 0;
    items.map((data: any) => {
      price +=
        data.Count * (data?.CustomerContextualPrice - data.DiscountAmount);
    });
    setTotalPrice(price);
  }, [items]);

  let [productDetails, setProductDetails] = useState<any>({});
  let [availStatusSpare, setAvailStatusSpare] = React.useState<string>();
  let [getSpareAvailable, setSpareAvailable] = React.useState<Number>(0);

  React.useEffect(() => {
    getEstimatedAvailabilityAsync(
      { callerContext: context.actionContext },
      {
        ProductIds: [productDetail?.RecordId],
        DefaultWarehouseOnly: true,
      }
    ).then((response: any) => {
      setAvailStatusSpare(
        response.ProductWarehouseInventoryAvailabilities[0]
          .PhysicalAvailableInventoryLevelCode
      );
      setSpareAvailable(
        response.ProductWarehouseInventoryAvailabilities[0].PhysicalAvailable
      );
    });
  }, [productDetail?.RecordId]);

  let [referenceNumber, setReferenceNumber] = useState<any[]>([]);
  let [spareReferenceNumber, setSpareReferenceNumber] = useState<any[]>();

  React.useEffect(() => {
    if (
      props.productDetails !== undefined &&
      props.productDetails.productDimensions !== undefined
    ) {
      let productDimensions = props.productDetails.productDimensions;

      let prods: any = [];
      prods = productDimensions[0]?.DimensionValues?.map((item: any) => {
        setProducts((oldArr: any) => [...oldArr, item]);
        return item;
        //prods = [...prods, item];
      });
      getVariantsByDimensionValuesAsync(
        { callerContext: context.actionContext, queryResultSettings: {} },
        selectedProductId,
        context.request.apiSettings.channelId,
        products
      ).then((res) => {
        setTimeout(() => {
          const datas = res.map((product: any, index: any) => {
            const splitURL = product.PrimaryImageUrl.split("^");
            return {
              RecordId: product.RecordId,
              imgUrl: product.PrimaryImageUrl,
              Name: product.Name,
              Price: product.Price,
              Count: 0,
              Behavior: product.Behavior,
              dimensionIdCheck: splitURL[splitURL.length - 2],
            };
          });

          let fewData: any[] = [];
          let recordIdss: any[] = [];
          let status: any[] = [];
          let quantityAvail: any[] = [];

          const mergedList = prods.map((t1: any) => ({
            ...t1,
            ...datas.find((t2: any) => {
              if (t2.dimensionIdCheck.includes(t1.DimensionId)) {
                return t2;
              }
            }),
          }));
          mergedList.map((d: any) => recordIdss.push(d.RecordId));
          // setFewItems(fewData);
          // setitems(mergedList);
          if (mergedList.length > 4) {
            setShowAll(false);
          } else {
            setShowAll(true);
          }

          getEstimatedAvailabilityAsync(
            { callerContext: context.actionContext },
            { ProductIds: recordIdss, DefaultWarehouseOnly: true }
          ).then((response) => {
            response.ProductWarehouseInventoryAvailabilities?.map(
              (data: any) => {
                status[recordIdss.indexOf(data.ProductId)] =
                  data.PhysicalAvailableInventoryLevelCode;
                quantityAvail[recordIdss.indexOf(data.ProductId)] =
                  data.PhysicalAvailable;
              }
            );
          });

          setAvailStatus(status);
          setRecordIds(recordIdss);
          setQuantityAvail(quantityAvail);
          const projectDomain: any = {
            ChannelId: props.context.request.apiSettings.channelId,
            CatalogId: Number(props.context.request.user.catalogId),
          };

          getActivePricesAsync(
            {
              callerContext: props.context.actionContext,
              queryResultSettings: {},
            },
            projectDomain,
            recordIdss,
            new Date(),
            null,
            [],
            true
          ).then((activePricesResponse: ProductPrice[]) => {
            const activePrices = activePricesResponse.map((price: any) => {
              const variantPriceSubtractCartOverallDiscount =
                price.DiscountLines.find((item: any) => {
                  if (item?.CustomerDiscountTypeValue === 3) {
                    return item.EffectiveAmount;
                  } else {
                    return null;
                  }
                });

              if (variantPriceSubtractCartOverallDiscount) {
                return {
                  ProductId: price.ProductId,
                  DiscountAmount:
                    price.DiscountAmount -
                    variantPriceSubtractCartOverallDiscount.EffectiveAmount,
                  CustomerContextualPrice: price.AdjustedPrice,
                  ValidFrom: price.ValidFrom,
                };
              } else {
                return {
                  ProductId: price.ProductId,
                  DiscountAmount: price.DiscountAmount,
                  CustomerContextualPrice: price.AdjustedPrice,
                  ValidFrom: price.ValidFrom,
                };
              }
            });

            const newItemsWithActivePrices = activePrices.map(
              (prices: any) => ({
                ...prices,
                ...mergedList.find((item: any) => {
                  if (item.RecordId == prices.ProductId) {
                    return { ...item };
                  }
                }),
              })
            );

            setitems(newItemsWithActivePrices);
            newItemsWithActivePrices.map((d: any, index: any) =>
              index < 4 ? fewData.push(d) : ""
            );
            setFewItems(fewData);
            let productIds: any[] = [];
            newItemsWithActivePrices.forEach((element) => {
              productIds.push(element.RecordId);
            });

            getProductsReferenceNumberAsync(
              { callerContext: props.context.actionContext },
              productIds
            ).then((resp) => {
              setReferenceNumber(resp);
            });
          });
        }, 100);
      });
    }
  }, [props.productDetails]);

  // React.useEffect(() => {
  //   const dimensionTypeValue: number = 1;
  //   let prods: any = [];

  //   // setMaxQuantity(productDetail.Behavior.MaximumQuantity);

  //   // getDimensionValuesAsync(
  //   //   {callerContext: context.actionContext, queryResultSettings: {}},
  //   //   selectedProductId,
  //   //   context.request.apiSettings.channelId,
  //   //   dimensionTypeValue,
  //   //   [],
  //   //   null,
  //   // ).then((res: any) => {
  //   //   prods = res.map((item: any) => {
  //   //     setProducts((oldArr: any) => [...oldArr, item]);
  //   //     return item;
  //   //   });
  //   // });
  //   if (
  //     props.productDetails !== undefined &&
  //     props.productDetails.productDimensions !== undefined
  //   ) {

  //   }
  // }, [props.productDetails.productDimensions]);

  React.useEffect(() => {
    getByIdAsync(
      {
        callerContext: props.context.actionContext,
      },
      selectedProductId,
      props.context.actionContext.requestContext.channel.RecordId
    ).then((res) => {
      setMaxQuantity({
        max: res?.Behavior?.MaximumQuantity,
        min: res?.Behavior?.MinimumQuantity,
      });
      setProductDetail(res);

      setProductDetails(res);
      getProductsReferenceNumberAsync(
        { callerContext: props.context.actionContext },
        [res.RecordId]
      ).then((resp) => {
        setSpareReferenceNumber(resp);
      });
    });
  }, []);

  React.useEffect(() => {
    getProductUrl();
  }, [title]);

  const getProductId = () => {
    localStorage.setItem("productState", JSON.stringify(items));
  };
  const getProductInfoFromSimpleProduct = (
    simpleProduct: SimpleProduct
  ): IProductInfo => {
    const recordId = simpleProduct.RecordId || "";

    return {
      // If master product id is not available then product record id is master product id
      productId: simpleProduct.MasterProductId
        ? simpleProduct.MasterProductId.toString()
        : recordId.toString(),
      productSku: recordId ? recordId.toString() : "",
      productName: simpleProduct.Name || simpleProduct.Description || "",
      productPrice: simpleProduct.Price ? simpleProduct.Price.toString() : "",
      productChannelId: "",
      productChannelName: "",
      productCategoryId: "",
      productCategoryName: "",
      productQuantity: "",
      productCurrency: "",
    };
  };

  const onProductQuantityChange = (index: any, val: any) => (e: any) => {
    //
    storeArray(index);
    setError(false);
    let newArr = [...items];
    if (props.app.config.enableStockCheck == true) {
      if (e.target.value > val) {
        newArr[index].Count = val;
        setitems(newArr);
      } else {
        setError(false);

        if (e.target.value > props.max) {
          newArr[index].Count = props.max;
          setitems(newArr);
        } else if (e.target.value < 0) {
          newArr[index].Count = 0;
          setitems(newArr);
        } else {
          newArr[index].Count = Number(e.target.value);
          setitems(newArr);
        }
      }
    } else {
      if (val == 0) {
        newArr[index].Count = Number(e.target.value);
        setitems(newArr);
      } else if (e.target.value > val) {
        newArr[index].Count = val;
        setitems(newArr);
      } else {
        newArr[index].Count = Number(e.target.value);
        setitems(newArr);
      }
    }
  };
  const addQty = (index: any) => {
    storeArray(index);
    setError(false);
    let newArr = [...items];
    if (props.app.config.enableStockCheck == true) {
      if (newArr[index].Count < props.max) {
        newArr[index].Count += 1;
        setitems(newArr);
      }
    } else {
      newArr[index].Count += 1;
      setitems(newArr);
    }
  };
  const subtractQty = (index: any) => {
    storeArray(index);
    setError(false);
    let newArr = [...items];
    if (newArr[index].Count > 0) {
      newArr[index].Count -= 1;
      setitems(newArr);
    }
  };

  const storeArray = (index: number) => {
    setDetectQtyChange({ index: 0, isChanged: true });
    storeIndexes = [...new Set([...storeIndexes, index])];
  };

  async function AddProductsToCart() {
    let cartArr: any = [];
    let cartProductInfo: any = [];

    items.map((product: any) => {
      if (
        product.Count !== 0 &&
        product.Count !== "" &&
        product.Count !== undefined
      ) {
        // let sumQuantity: any = 0;
        // sumQuantity += product.Count;
        cartArr.push({
          ProductId: product.RecordId,
          Quantity: Number(product.Count),
          CatalogId: Number(props.context.request.user.catalogId), //Saqlain Customization - Catalog ID Added
          TrackingId: "",
          UnitOfMeasureSymbol: productDetails.DefaultUnitOfMeasure,
        });
        cartProductInfo.push({
          Name: product.Name,
          Price: product.CustomerContextualPrice,
          imgUrl: product.imgUrl,
          Quantity: Number(product.Count),
        });
      }
    });
    if (multipleError.indexOf("true") == -1) {
      if (cartArr.length === 0) {
        setError(true);
      } else {
        try {
          setMaxQuantityError(false);
          const cartState = await getCartState(context.actionContext);
          await addCartLinesAsync(
            { callerContext: props.context.actionContext },
            cartState.cart.Id,
            cartArr,
            cartState.cart.Version!
          );
          setCartRes({ count: cartArr.length, data: cartProductInfo });

          for (let i = 0; i < cartArr.length; i++) {
            const cartLine: CartLine = cartArr[i];

            if (cartLine.ProductId) {
              await getByIdAsync(
                {
                  callerContext: props.context.actionContext,
                },
                cartLine.ProductId,
                props.context.actionContext.requestContext.channel.RecordId
              ).then(
                (result: any) => {
                  return getProductInfoFromSimpleProduct(result);
                },
                (reason: any) => {
                  throw new Error(
                    `Failed to get product details for product ${cartLine.ProductId}.\n${reason}`
                  );
                }
              );
            }
          }

          const {
            data: { cart: cart },
          } = props;

          // cart.result?.refreshCart({});
          const cartVersion = cart.result?.cart?.Version;
          const cartId = cart.result?.cart?.Id;

          await validateForCheckoutAsync(
            {
              callerContext: props.context.actionContext,
              bypassCache: "none",
            },
            cartId,
            cartVersion
          )
            .then(() => {
              cartState?.refreshCart({});
            })
            .catch((error: any) => {
              if (error) {
                const cartState = cart.result;
                cartState?.refreshCart({});
              }
            });
          openNotification();
          props.callbacks?.changeModalOpen(false);
        } catch (e) {
          openNotificationMin(e);
        }
      }
    }
  }
  async function addToCartSpare() {
    if (spareQuantity > 0) {
      if (props.app.config.enableStockCheck == false) {
        setError(false);
        try {
          const cartState = await getCartState(context.actionContext);
          await addCartLinesAsync(
            { callerContext: props.context.actionContext },
            cartState.cart.Id,
            [
              {
                ProductId: productDetails?.RecordId,
                Quantity: Number(spareQuantity),
                TrackingId: "",
                CatalogId: Number(props.context.request.user.catalogId), //Saqlain Customization - Catalog ID Added
                UnitOfMeasureSymbol: productDetails.DefaultUnitOfMeasure,
                RequestedDeliveryDate: new Date(),
              },
            ],
            cartState.cart.Version!
          );
          openNotification();
          cartState?.refreshCart({});
          props.callbacks?.changeModalOpen(false);
        } catch (e) {
          openNotificationMin(e);
        }
      } else {
        setError(false);
        try {
          const cartState = await getCartState(context.actionContext);
          await addCartLinesAsync(
            { callerContext: props.context.actionContext },
            cartState.cart.Id,
            [
              {
                ProductId: productDetails?.RecordId,
                Quantity: Number(spareQuantity),
                TrackingId: "",
                CatalogId: Number(props.context.request.user.catalogId), //Saqlain Customization - Catalog ID Added
                UnitOfMeasureSymbol: productDetails.DefaultUnitOfMeasure,
                RequestedDeliveryDate: new Date(),
              },
            ],
            cartState.cart.Version!
          );
          openNotification();
          cartState?.refreshCart({});
          props.callbacks?.changeModalOpen(false);
        } catch (e) {
          openNotificationMin(e);
        }
      }
    } else {
      setError(true);
    }
  }

  React.useEffect(() => {
    setError(false);
  }, [items, spareQuantity]);

  const getProductUrl = () => {
    const name = title?.props?.text;
    const splitArr = name?.split(" ");
    const joinDash = splitArr?.join("-");
    const url = joinDash?.toLowerCase();
    let finalUrl;

    const locale =
      props.context.request.locale != "en-GB"
        ? props.context.request.locale
        : "";

    const origin = props.context.request.url.requestUrl.origin;

    if (locale.toString().length > 0) {
      finalUrl = `${origin}/${locale}/${url}/${selectedProductId}.p`;
    } else {
      finalUrl = `/${url}/${selectedProductId}.p`;
    }

    setProductUrl(finalUrl);
  };

  if (loading) {
    return <>{loading}</>;
  }

  const _handleAtPClick = async () => {
    const AtpApiRequest: ATPAPIRequestObject[] = [];
    setAtpLoader(true);

    if (productDetails?.ProductTypeValue === 4) {
      if (spareQuantity <= 0) {
        setError(true);
        setAtpLoader(false);
        return;
      }
      let obj = {
        ATPList: AtpApiRequest,
      };
      let refIDs: any[] = [];
      spareReferenceNumber?.map((item) => {
        refIDs.push(item.ReferenceNumber);
      });

      if (refIDs?.length) {
        refIDs.map((pID: number, index: number) => {
          obj.ATPList.push({
            productReferenceNumber: pID,
            quantity: spareQuantity,
            siteId:
              props.data.customerInformation.result.ExtensionProperties[3].Value
                .StringValue,
            warehouse:
              props.data.customerInformation.result.ExtensionProperties[2].Value
                .StringValue,
          });
        });
      }

      const atpRes = await getAtpDateAction(
        new BritaxGetAtpDateInput(
          obj.ATPList,
          props.data.atpDateTokenQuickView.result?.token
        ),
        props.context.actionContext
      );
      setAtpLoader(false);

      atpRes.atpList.map((item: any, index: any) => {
        if (item.itemId == refIDs[index] && spareQuantity) {
          setSimpleProductAtpDate([
            getOnlyDate(item.atpDate, props.resources.atpNoDateFound),
          ]);
        }
      });
    } else if (productDetails?.ProductTypeValue === 3) {
      if (atpDate?.length !== 0) {
        if (atpDate?.every((obj) => obj.currentQuantity === 0)) {
          setError(true);
          setAtpLoader(false);
          return;
        }

        let obj = {
          ATPList: AtpApiRequest,
        };

        atpDate.map((item: ATPStateObjectType) => {
          if (item.currentQuantity > 0) {
            obj.ATPList.push({
              productReferenceNumber: item.productReferenceNumber,
              quantity: item.currentQuantity,
              siteId:
                props.data.customerInformation.result.ExtensionProperties[3]
                  .Value.StringValue,
              warehouse:
                props.data.customerInformation.result.ExtensionProperties[2]
                  .Value.StringValue,
            });
          }
        });

        const res = await getAtpDateAction(
          new BritaxGetAtpDateInput(
            obj.ATPList,
            props.data.atpDateTokenQuickView.result?.token
          ),
          props.context.actionContext
        );
        setAtpLoader(false);

        res.atpList.map((item: any, index: number) => {
          atpDate.map((atp) => {
            if (item.itemId === atp.productReferenceNumber) {
              setatpDate((prev) => [
                ...prev,
                (atp.atpDate = getOnlyDate(
                  item.atpDate,
                  props.resources.atpNoDateFound
                )),
                (atp.lastQuantityUsedToFetchDate = item.qty),
              ]);
            }
          });
        });
      } else {
        setError(true);
        setAtpLoader(false);
      }
    }
    storeIndexes.length = 0;
    setDetectQtyChange({ index: 0, isChanged: false });
  };

  const getText = (text: any) => {
    // For Text that is shorter than desired length
    if (text.length <= 120) return text;
    // If text is longer than desired length & showMore is true
    if (text.length > 120 && showMore) {
      return (
        <>
          <p>{text}</p>
          <a className="load-text" onClick={() => setShowMore(false)}>
            {props.resources.showLess}
          </a>
        </>
      );
    }
    // If text is longer than desired length & showMore is false
    if (text.length > 120) {
      return (
        <>
          <p>{text.slice(0, 120)}</p>
          <a className="load-text" onClick={() => setShowMore(true)}>
            {props.resources.showMore}
          </a>
        </>
      );
    }
  };

  // const currencyCode = data.customerInformation.result.CurrencyCode;

  var fullForms1: any = [];
  fullForms1["OOS"] = props.resources.outOfStock;
  fullForms1["LOW"] = props.resources.lowInStock;
  fullForms1["AVAIL"] = props.resources.available;

  const renderTooltip = (props: any, e: any) => (
    <Tooltip id="button-tooltip" {...e}>
      <span>{props}</span>
    </Tooltip>
  );

  function renderSpareImage(
    gridSettings?: IGridSettings,
    imageUrl?: string,
    altText?: string
  ): JSX.Element | null {
    const defaultImageSettings: IImageSettings = {
      viewports: {
        xs: { q: `w=100&h=100&m=6`, w: 0, h: 0 },
        lg: { q: `w=100&h=100&m=6`, w: 0, h: 0 },
        xl: { q: `w=100&h=&m=6`, w: 0, h: 0 },
      },
      lazyload: true,
      cropFocalRegion: true,
    };
    if (!imageUrl || !gridSettings) {
      return null;
    }
    const img: IImageData = {
      src: imageUrl,
      // src:'',
      altText: altText ? altText : "",
    };
    const imageProps = {
      gridSettings: gridSettings,
      shouldUseThumbnails: true,
      imageSettings: defaultImageSettings,
    };
    return <Image {...img} {...imageProps} loadFailureBehavior="empty" />;
  }

  var multipleError: string[] = [];
  if (productDetails?.ProductTypeValue == 4) {
    // when it is spare part or a Accessories
    return (
      <>
        <Node {...MediaGalleryContainerProps}>
          <Node {...CarouselProps} />
        </Node>
        <Node {...ProductInfoContainerProps}>
          <Modal show={show} contentClassName="msc-modal__content" centered>
            <Modal.Header
              className="msc-modal__header"
              key={cartRes.RecordId}
              closeButton
            >
              <Modal.Title>{cartRes.count} item added</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="pdp-quantity">
                {cartRes.data &&
                  cartRes.data.map((item: any, index: any) => {
                    return (
                      <div className="product-variant" key={index}>
                        <div className="variant-blk">
                          <div className="img-box">
                            <img
                              src={
                                context.request.apiSettings.baseImageUrl +
                                item.imgUrl
                              }
                            />
                            <h5 className="name">{item.Name}</h5>
                          </div>
                        </div>
                        <div className="variant-blk">
                          <h5>
                            {props.context.cultureFormatter.formatCurrency(
                              Number(item.Price.toFixed(2))
                            )}
                          </h5>
                        </div>
                        <div className="variant-blk">
                          <span className="quantity-box">{item.Quantity}</span>
                        </div>
                        <div className="variant-blk">
                          <span className="quantity-box">
                            <h5>
                              {props.context.cultureFormatter.formatCurrency(
                                Number((item.Quantity * item.Price).toFixed(2))
                              )}
                            </h5>
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button href="/cart" variant="secondary">
                View Cart and Checkout
              </Button>
              <Button variant="primary">Continue Shopping</Button>
            </Modal.Footer>
          </Modal>
          {title}

          <p className="description-text">
            {getText(description?.props?.children || "")}
          </p>

          {rating}
          {/* {configure && _renderConfigure(configure)} */}
          {keyInPrice && _renderKeyInPrice(keyInPrice)}

          <div className="pdp-quantity">
            <div className="product-variant-head">
              <div className="blk">
                <h2>{props.resources.image}</h2>
              </div>
              {isRetired != true ? (
                <div className="blk">
                  <h2>{props.resources.price}</h2>
                </div>
              ) : (
                <></>
              )}
              {isRetired == false ? (
                <div className="blk">
                  <h2>{props.resources.quantity}</h2>
                </div>
              ) : (
                <></>
              )}

              {isRetired != true ? (
                <div className="blk">
                  <h2>{props.resources.subtotal}</h2>
                </div>
              ) : (
                <></>
              )}
              {isRetired != true ? (
                <div className="blk">
                  <h2>{props.resources.atpTitle}</h2>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="product-variant">
              <div className="variant-blk">
                <span className="img-box">
                  {renderSpareImage(
                    context.request?.gridSettings,
                    context.request.apiSettings.baseImageUrl +
                      productDetails.PrimaryImageUrl +
                      "&h=30",
                    productDetails.Name
                  )}
                  <span>
                    {spareReferenceNumber &&
                    spareReferenceNumber[0]?.ReferenceNumber
                      ? spareReferenceNumber[0]?.ReferenceNumber
                      : " "}
                  </span>

                  {isRetired != true ? (
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={(e) =>
                        renderTooltip(
                          availStatusSpare
                            ? fullForms1[availStatusSpare]
                            : null,
                          e
                        )
                      }
                    >
                      <span className={`status ${availStatusSpare}`}>
                        {availStatusSpare ? fullForms1[availStatusSpare] : null}
                      </span>
                    </OverlayTrigger>
                  ) : (
                    <></>
                  )}
                </span>
              </div>
              {isRetired != true ? (
                <div className="variant-blk">
                  {price.props.data.price.DiscountAmount > 0 ? (
                    <h5>
                      <s>
                        {props.context.cultureFormatter.formatCurrency(
                          Number(price.props.data.price.AdjustedPrice)
                        )}
                      </s>
                    </h5>
                  ) : (
                    <h5>
                      {props.context.cultureFormatter.formatCurrency(
                        Number(price.props.data.price.CustomerContextualPrice)
                      )}
                    </h5>
                  )}
                  {price.props.data.price.DiscountAmount > 0 ? (
                    <h5>
                      {props.context.cultureFormatter.formatCurrency(
                        Number(price.props.data.price.CustomerContextualPrice)
                      )}
                    </h5>
                  ) : null}
                </div>
              ) : (
                <></>
              )}
              {isRetired == false ? (
                <div className="variant-blk">
                  <div className="quantity-box">
                    {props.app.config.enableStockCheck == true ? (
                      availStatusSpare == "OOS" ? (
                        <button
                          onClick={() => {
                            spareQuantity > 0 &&
                              changeSpareQuantity(spareQuantity - 1);
                          }}
                          disabled={true}
                        >
                          -
                        </button>
                      ) : (
                        <button
                          className={spareQuantity > 0 ? " " : "disabled"}
                          onClick={() => {
                            spareQuantity > 0 &&
                              changeSpareQuantity(spareQuantity - 1);
                          }}
                        >
                          -
                        </button>
                      )
                    ) : (
                      <button
                        className={spareQuantity > 0 ? " " : "disabled"}
                        onClick={() => {
                          spareQuantity > 0 &&
                            changeSpareQuantity(spareQuantity - 1);
                        }}
                      >
                        -
                      </button>
                    )}
                    {props.app.config.enableStockCheck == true ? (
                      availStatusSpare == "OOS" ? (
                        <input type="number" disabled />
                      ) : (
                        <input
                          type="number"
                          id={"randomID"}
                          value={spareQuantity}
                          onKeyDown={(event: any) => {
                            if (event.key === ".") {
                              event.preventDefault();
                            }
                          }}
                          onInput={(event: any) =>
                            (event.target.value = event.target.value.replace(
                              /[^0-9]*/g,
                              ""
                            ))
                          }
                          max={
                            Number(getSpareAvailable) <
                            Number(
                              props.context.app.config
                                .maxQuantityForCartLineItem
                            )
                              ? Number(
                                  props.context.app.config
                                    .maxQuantityForCartLineItem
                                )
                              : Number(getSpareAvailable)
                          }
                          onChange={(e) => {
                            changeSpareQuantity(Number(e.target.value));
                          }}
                        />
                      )
                    ) : productDetail?.Behavior?.MaximumQuantity == 0 &&
                      productDetail?.Behavior?.MinimumQuantity == 0 ? (
                      <input
                        type="number"
                        id={"randomID"}
                        value={spareQuantity}
                        onKeyDown={(event: any) => {
                          if (event.key === ".") {
                            event.preventDefault();
                          }
                        }}
                        onInput={(event: any) =>
                          (event.target.value = event.target.value.replace(
                            /[^0-9]*/g,
                            ""
                          ))
                        }
                        onChange={(e) => {
                          changeSpareQuantity(Number(e.target.value));
                        }}
                      />
                    ) : productDetail?.Behavior?.MaximumQuantity == 0 &&
                      productDetail?.Behavior?.MinimumQuantity != 0 ? (
                      <input
                        type="number"
                        id={"randomID"}
                        value={spareQuantity}
                        onKeyDown={(event: any) => {
                          if (event.key === ".") {
                            event.preventDefault();
                          }
                        }}
                        min={productDetail?.Behavior?.MinimumQuantity}
                        onInput={(event: any) =>
                          (event.target.value = event.target.value.replace(
                            /[^0-9]*/g,
                            ""
                          ))
                        }
                        onChange={(e) => {
                          changeSpareQuantity(Number(e.target.value));
                        }}
                      />
                    ) : (
                      <input
                        type="number"
                        id={"randomID"}
                        value={spareQuantity}
                        onKeyDown={(event: any) => {
                          if (event.key === ".") {
                            event.preventDefault();
                          }
                        }}
                        onInput={(event: any) =>
                          (event.target.value = event.target.value.replace(
                            /[^0-9]*/g,
                            ""
                          ))
                        }
                        onChange={(e) => {
                          if (
                            e.target.value <=
                            productDetail?.Behavior?.MaximumQuantity
                          ) {
                            changeSpareQuantity(Number(e.target.value));
                          } else {
                            openNotificationLimit();
                          }
                        }}
                      />
                    )}
                    {props.app.config.enableStockCheck == true ? (
                      availStatusSpare == "OOS" ? (
                        <button
                          onClick={() => {
                            changeSpareQuantity(spareQuantity + 1);
                          }}
                          disabled={true}
                          className="disabled"
                        >
                          +
                        </button>
                      ) : spareQuantity < maxQuantity ? (
                        <button
                          onClick={() => {
                            changeSpareQuantity(spareQuantity + 1);
                          }}
                        >
                          +
                        </button>
                      ) : (
                        <button
                          className="disabled"
                          onClick={() => {
                            changeSpareQuantity(spareQuantity + 1);
                          }}
                        >
                          +
                        </button>
                      )
                    ) : productDetail?.Behavior?.MaximumQuantity == 0 &&
                      productDetail?.Behavior?.MinimumQuantity == 0 ? (
                      <button
                        onClick={() => {
                          changeSpareQuantity(spareQuantity + 1);
                        }}
                      >
                        +
                      </button>
                    ) : productDetail?.Behavior?.MaximumQuantity == 0 &&
                      productDetail?.Behavior?.MinimumQuantity != 0 ? (
                      <button
                        onClick={() => {
                          changeSpareQuantity(spareQuantity + 1);
                        }}
                      >
                        +
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          spareQuantity <
                          productDetail?.Behavior?.MaximumQuantity
                            ? changeSpareQuantity(spareQuantity + 1)
                            : openNotificationLimit();
                        }}
                      >
                        +
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}

              {isRetired != true ? (
                <div className="variant-blk">
                  <h5>
                    {props.context.cultureFormatter.formatCurrency(
                      Number(
                        (
                          price.props.data.price.CustomerContextualPrice *
                          spareQuantity
                        ).toFixed(2)
                      )
                    )}
                  </h5>
                </div>
              ) : (
                <></>
              )}
              {isRetired != true ? (
                <div className="variant-blk">
                  {simpleProductAtpDate && simpleProductAtpDate.length
                    ? detectQtyChange.isChanged
                      ? "NA"
                      : simpleProductAtpDate
                    : ""}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          {error ? (
            <div
              className="msc-alert msc-alert-danger"
              role="alert"
              aria-live="assertive"
              aria-label="There are some issues"
            >
              <div className="fade show">
                <div className="msc-alert__header" aria-hidden="true">
                  <span className="msi-exclamation-triangle"></span>
                  <span>{props.resources.issue}</span>
                </div>
                <div className="msc-alert__line">
                  {props.resources.reqOneQuantity}
                </div>
              </div>
            </div>
          ) : null}
          {productDetail?.Behavior?.MultipleOfQuantity > 1 &&
          spareQuantity % productDetail?.Behavior?.MultipleOfQuantity != 0 ? (
            <span className="msc-alert msc-alert-danger-custom-spare">
              {props.resources.multiOf}{" "}
              {productDetail?.Behavior?.MultipleOfQuantity}{" "}
              {props.resources.isAllowed}.
            </span>
          ) : null}
          {/* <div className="price">
            €{(productDetails?.Price * spareQuantity).toFixed(2)}
          </div> */}

          <div className="per-unit-box-quickview">
            {productDetail?.Behavior?.MultipleOfQuantity > 1 &&
            spareQuantity % productDetail?.Behavior?.MultipleOfQuantity != 0 ? (
              <button
                disabled={true}
                className="msc-add-to-cart custom-btn-add-to-cart"
              >
                {props.resources.addToCart}
              </button>
            ) : (
              addToCart &&
              _renderAddToCart(
                addToCart,
                context,
                addToCartSpare,
                props,
                isRetired,
                setIsRetired,
                error
              )
            )}

            <a
              href={productUrl}
              className="ms-quickView__seeDetailsbutton msc-btn"
              onClick={getProductId}
            >
              {props.resources.viewDetails}
            </a>
            <a
              className={`ms-quickView__atpCalculteButton msc-btn ms-order-history__btn-more ${
                atpLoader ? "is-busy" : ""
              }`}
              onClick={_handleAtPClick}
            >
              {props.resources.atpCalculateButton}
            </a>
          </div>
          <span className="ms-quickView-atp-explaination">
            {props.resources.atpNoDateFoundExplaination}
          </span>

          <p className="card-discount-explaination-simple-products">
            {props.resources.cartDiscountExplaination}
          </p>
          {inventoryLabel}
        </Node>
      </>
    );
  } else {
    return (
      <>
        <Node {...MediaGalleryContainerProps}>
          <Node {...CarouselProps} />
        </Node>
        <Node {...ProductInfoContainerProps}>
          <Modal show={show} contentClassName="msc-modal__content" centered>
            <Modal.Header
              className="msc-modal__header"
              key={cartRes.RecordId}
              closeButton
            >
              <Modal.Title>{cartRes.count} item added</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="product-variant-head">
                <div className="blk">
                  <h2>{props.resources.variant}</h2>
                </div>
                {isRetired != true ? (
                  <div className="blk">
                    <h2>{props.resources.price}</h2>
                  </div>
                ) : (
                  <></>
                )}
                {isRetired != true ? (
                  <div className="blk">
                    <h2>{props.resources.quantity}</h2>
                  </div>
                ) : (
                  <></>
                )}
                {isRetired != true ? (
                  <div className="blk">
                    <h2>{props.resources.subtotal}</h2>
                  </div>
                ) : (
                  <></>
                )}
                {isRetired != true ? (
                  <div className="blk">
                    <h2>{props.resources.atpTitle}</h2>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="pdp-quantity">
                {cartRes.data &&
                  cartRes.data.map((item: any, index: any) => {
                    return (
                      <div className="product-variant" key={index}>
                        <div className="variant-blk">
                          <div className="img-box">
                            <img
                              src={
                                context.request.apiSettings.baseImageUrl +
                                item.imgUrl
                              }
                            />
                            <h5 className="name">{item.Name}</h5>
                          </div>
                        </div>
                        {isRetired != true ? (
                          <div className="variant-blk">
                            <h5>
                              {" "}
                              {props.context.cultureFormatter.formatCurrency(
                                Number(item.Price.toFixed(2))
                              )}
                            </h5>
                          </div>
                        ) : (
                          <></>
                        )}
                        {isRetired != true ? (
                          <div className="variant-blk">
                            <span className="quantity-box">
                              {item.Quantity}
                            </span>
                          </div>
                        ) : (
                          <></>
                        )}
                        {isRetired != true ? (
                          <div className="variant-blk">
                            {props.context.cultureFormatter.formatCurrency(
                              Number((item.Quantity * item.Price).toFixed(2))
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  })}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button href="/cart" variant="secondary">
                View Cart and Checkout
              </Button>
              <Button variant="primary">Continue Shopping</Button>
            </Modal.Footer>
          </Modal>
          {title}

          {isRetired == true ? (
            <span className="isretired">{props.resources.productRetired}</span>
          ) : (
            <></>
          )}

          <p className="description-text">
            {getText(description?.props?.children || "")}
          </p>

          {rating}
          {/* {configure && _renderConfigure(configure)} */}
          {keyInPrice && _renderKeyInPrice(keyInPrice)}
          <div className="product-variant-head">
            <div className="blk">
              <h2>{props.resources.variant}</h2>
            </div>
            {isRetired != true ? (
              <div className="blk">
                <h2>{props.resources.price}</h2>
              </div>
            ) : (
              <></>
            )}
            {isRetired == false ? (
              <div className="blk">
                <h2>{props.resources.quantity}</h2>
              </div>
            ) : (
              <></>
            )}

            {isRetired != true ? (
              <div className="blk">
                <h2>{props.resources.subtotal}</h2>
              </div>
            ) : (
              <></>
            )}
            {isRetired != true ? (
              <div className="blk">
                <h2>{props.resources.atpTitle}</h2>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="pdp-quantity">
            {showAll
              ? quantity &&
                items &&
                products &&
                items.map((product: any, index: any) => {
                  return _renderQuantity(
                    quantity,
                    product,
                    index,
                    onProductQuantityChange,
                    context,
                    products[index],
                    addQty,
                    subtractQty,
                    availStatus,
                    recordsIds,
                    props,
                    quantityAvail,
                    isRetired,
                    multipleError,
                    productDetail,
                    openNotificationLimit,
                    referenceNumber,
                    renderTooltip,
                    fullForms,
                    atpDate && atpDate[index],
                    detectQtyChange
                  );
                })
              : quantity &&
                fewItems &&
                products &&
                fewItems.map((product: any, index: any) => {
                  return _renderQuantity(
                    quantity,
                    product,
                    index,
                    onProductQuantityChange,
                    context,
                    products[index],
                    addQty,
                    subtractQty,
                    availStatus,
                    recordsIds,
                    props,
                    quantityAvail,
                    isRetired,
                    multipleError,
                    productDetail,
                    openNotificationLimit,
                    referenceNumber,
                    renderTooltip,
                    fullForms,
                    atpDate && atpDate[index],
                    detectQtyChange
                  );
                })}
            <div className="viewmore">
              {items.length > 4 ? (
                showAll ? (
                  <button onClick={() => setShowAll(false)}>
                    {props.resources.viewLess} (4)
                  </button>
                ) : (
                  <button onClick={() => setShowAll(true)}>
                    {props.resources.viewAll} ({items.length})
                  </button>
                )
              ) : null}
              <span className="viewmore__price">
                {isRetired != true
                  ? props.context.cultureFormatter.formatCurrency(
                      Number(totalPrice.toFixed(2))
                    )
                  : null}
              </span>
            </div>
          </div>
          {error ? (
            <div
              className="msc-alert msc-alert-danger"
              role="alert"
              aria-live="assertive"
              aria-label="There are some issues"
            >
              <div className="fade show">
                <div className="msc-alert__header" aria-hidden="true">
                  <span className="msi-exclamation-triangle"></span>
                  <span>{props.resources.issue}</span>
                </div>
                <div className="msc-alert__line">
                  {" "}
                  {props.resources.reqOneQuantity}
                </div>
              </div>
            </div>
          ) : null}
          {MaxQuantityError ? (
            <div
              className="msc-alert msc-alert-danger"
              role="alert"
              aria-live="assertive"
              aria-label="There are some issues"
            >
              <div className="fade show">
                <div className="msc-alert__header" aria-hidden="true">
                  <span className="msi-exclamation-triangle"></span>
                  <span>{props.resources.issue}</span>
                </div>
                <div className="msc-alert__line">
                  {props.resources.reachedMaximumText} {maxQuantity}{" "}
                  {props.resources.quantity}.
                </div>
              </div>
            </div>
          ) : null}

          <div className="per-unit-box">
            {addToCart &&
              _renderAddToCart(
                addToCart,
                context,
                AddProductsToCart,
                props,
                isRetired,
                setIsRetired
              )}

            <a
              href={productUrl}
              className="ms-quickView__seeDetailsbutton msc-btn"
              onClick={getProductId}
            >
              {props.resources.viewDetails}
            </a>
            {isRetired === false ? (
              <a
                className={`ms-quickView__atpCalculteButton msc-btn ms-order-history__btn-more ${
                  atpLoader ? "is-busy" : ""
                }`}
                onClick={_handleAtPClick}
              >
                {props.resources.atpCalculateButton}
              </a>
            ) : (
              <></>
            )}
            {/* <a
              className={`ms-quickView__atpCalculteButton msc-btn ms-order-history__btn-more ${
                atpLoader ? "is-busy" : ""
              }`}
              onClick={_handleAtPClick}
            >
              {props.resources.atpCalculateButton}
            </a> */}
          </div>
          <span className="ms-quickView-atp-explaination">
            {props.resources.atpNoDateFoundExplaination}
          </span>
          <p className="card-discount-explaination">
            {props.resources.cartDiscountExplaination}
          </p>
          {inventoryLabel}
        </Node>
      </>
    );
  }
};

/**
 * Functional component which renders quick view button and popup based by the given props file.
 * @param {IQuickviewViewProps} props - Configuration for the quick view component.
 * @returns {React.FC<IQuickviewViewProps>} - Functional component of the quick view.
 */
export const QuickViewFunctionalComponent: React.FC<IQuickviewViewProps> = (
  props: IQuickviewViewProps
) => {
  const { quickView, quickViewButton, isModalOpen } = props;

  return (
    <Module {...quickView}>
      {quickViewButton}
      {isModalOpen && _renderQuickViewPopup(props)}
    </Module>
  );
};

export default QuickViewFunctionalComponent;
